<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <b-row>
        <b-col
          cols="6"
          md="3"
          sm="6"
        >

          <b-button
            v-b-toggle.collapse-filter
            variant="outline-primary"
            class="mb-2"
          >
            <feather-icon
              icon="FilterIcon"
              size="16"
            />&nbsp;{{ $t('Filters') }}
          </b-button>

        </b-col>

        <b-col
          cols="6"
          md="3"
          sm="6"
        >
          <b-button
            variant="flat-primary"
            class="mb-2 text-right border-top border-bottom"
            block
          >
            <span class="font-weight-light">Total Amount </span>{{ totalAmount.toLocaleString() }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="3"
          sm="6"
        >
          <b-button
            variant="flat-primary"
            class="mb-2 text-right border-top border-bottom"
            block
          >
            <span class="font-weight-light">Total Discount </span>{{ totalDiscount.toLocaleString() }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="3"
          sm="6"
        >
          <b-button
            variant="flat-primary"
            class="mb-2 text-right border-top border-bottom"
            block
          >
            <span class="font-weight-light">Net Total </span>{{ netTotal.toLocaleString() }}
          </b-button>
        </b-col>
      </b-row>

      <b-collapse
        id="collapse-filter"
      >
        <!-- Filters -->
        <sale-list-filters
          :date-filter.sync="dateFilter"
          :date-filter-value.sync="dateFilterValue"
          :clear-date.sync="clearDate"
          :branch-filter.sync="branchFilter"
          :branch-options="branchOptions"
          :staff-filter.sync="staffFilter"
          :staff-options="staffOptions"
          :client-filter.sync="clientFilter"
          :client-options="clientOptions"
          :status-filter.sync="statusFilter"
          :status-options="statusOptions"
          :sale-region-filter.sync="saleRegionFilter"
          :sale-region-options="saleRegionOptions"
          :item-filter.sync="itemFilter"
          :item-options="itemOptions"
        />
      </b-collapse>

      <!-- Table Container Card -->
      <b-card
        :title="$t('Sales')"
        class="mb-0"
      >

        <div class="mb-2 mt-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start pl-0 mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')"
                  @keyup.enter="search"
                />
                <b-button
                  v-if="$can('create', 'sale')"
                  variant="primary"
                  @click="add"
                >
                  <span class="text-nowrap">{{ $t('New Invoice') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refSaleListTable"
          class="position-relative"
          :items="fetchSales"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          stacked="sm"
        >

          <!-- Column: Branch -->
          <template #cell(client)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.client.name }}</span>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ resolveStatus(data.item.status) }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link
              :to="{ name: 'sale-view', params: { id: data.item.id } }"
              class="btn btn-outline-secondary btn-icon mr-1"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
              />
            </b-link>

            <b-link
              v-if="$can('update', 'sale')"
              :to="{ name: 'sale-edit', params: { id: data.item.id } }"
              class="btn btn-outline-secondary btn-icon mr-1"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-link>

            <b-link
              v-if="$can('delete', 'sale')"
              class="btn btn-outline-secondary btn-icon"
              @click="del(data.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
            </b-link>

            <!-- <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="$can('update', 'sale')"
                :to="{ name: 'sale-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('delete', 'sale')"
              >
                <feather-icon icon="TrashIcon" />
                <span
                  class="align-middle ml-50"
                  @click="del(data.item.id)"
                >
                  Delete</span>
              </b-dropdown-item>
            </b-dropdown> -->

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalSales"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

    </div>

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge, BCollapse, VBToggle,
  BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import SaleListFilters from './SaleListFilters.vue'
import storeModule from '../../../common/storeModule'
import useSaleList from './useSaleList'
import saleStoreModule from '../saleStoreModule'

export default {
  components: {
    SaleListFilters,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,
    BBadge,
    BCollapse,

    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      branchOptions: [],
      staffOptions: [],
      clientOptions: [],
      saleRegionOptions: [],
      tableColumns: [
        { key: 'id', label: this.$t('ID'), sortable: true },
        { key: 'sale_date', label: this.$t('Date'), sortable: true },
        { key: 'client', label: this.$t('Client'), sortable: true },
        { key: 'net_amount', label: this.$t('Net'), sortable: true },
        { key: 'reference', label: this.$t('Ref'), sortable: true },
        { key: 'branch_name', label: this.$t('Branch'), sortable: true },
        { key: 'status', label: this.$t('Status'), sortable: true },
        { key: 'actions', label: this.$t('Actions') },
      ],
      statusOptions: [
      // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
        { label: this.$t('Ordered'), value: '0' },
        { label: this.$t('Unpaid'), value: '1' },
        { label: this.$t('Partially Paid'), value: '2' },
        { label: this.$t('Paid'), value: '3' },
        { label: this.$t('Cancelled'), value: '4' },
      ],
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'sale'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const clearDate = null

    const {
      fetchSales,
      perPage,
      currentPage,
      totalSales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSaleListTable,
      refetchData,
      totalAmount,
      totalDiscount,
      netTotal,
      resolveStatusVariant,

      // Filters
      branchFilter,
      staffFilter,
      clientFilter,
      saleRegionFilter,
      itemFilter,
      statusFilter,
      dateFilter,
      dateFilterValue,

      itemOptions,

    } = useSaleList()

    return {

      fetchSales,
      perPage,
      currentPage,
      totalSales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSaleListTable,
      refetchData,
      totalAmount,
      totalDiscount,
      netTotal,
      resolveStatusVariant,

      clearDate,

      // Extra Filters
      branchFilter,
      staffFilter,
      clientFilter,
      saleRegionFilter,
      itemFilter,
      statusFilter,
      dateFilter,
      dateFilterValue,

      itemOptions,

    }
  },
  mounted() {
    this.loadBranches()
    this.loadStaffs()
    this.loadClients()
    this.loadSaleRegions()
  },
  methods: {
    // 0 = new, 1 = unpaid, 2 = partially paid, 3 = paid, 4 = cancelled
    resolveStatus(status) {
      if (status === 0) return this.$t('Ordered')
      if (status === 1) return this.$t('Unpaid')
      if (status === 2) return this.$t('Partially Paid')
      if (status === 3) return this.$t('Paid')
      if (status === 4) return this.$t('Cancelled')
      return this.$t('Unknown')
    },
    loadBranches() {
      store
        .dispatch('common/fetchBranches')
        .then(response => {
          const { branches } = response.data.data
          for (let i = 0; i < branches.length; i += 1) {
            this.branchOptions.push({ label: branches[i].name, value: branches[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadStaffs() {
      store
        .dispatch('common/fetchStaffs')
        .then(response => {
          const { staffs } = response.data.data
          for (let i = 0; i < staffs.length; i += 1) {
            this.staffOptions.push({ label: staffs[i].name, value: staffs[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadClients() {
      store
        .dispatch('common/fetchClients')
        .then(response => {
          const { clients } = response.data.data
          for (let i = 0; i < clients.length; i += 1) {
            this.clientOptions.push({ label: clients[i].name, value: clients[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadSaleRegions() {
      store
        .dispatch('common/fetchSaleRegions')
        .then(response => {
          const { sale_regions } = response.data.data
          for (let i = 0; i < sale_regions.length; i += 1) {
            this.saleRegionOptions.push({ label: sale_regions[i].name, value: sale_regions[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.refetchData()
    },
    add() {
      this.$router.push({ name: 'sale-new' })
    },
    del(id) {
      const that = this
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('sale/del', id)
            .then(response => {
              if (response.status === 200) {
                that.refetchData()
              } else {
                console.log(response)
              }
            })
            .catch(error => {
              window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
