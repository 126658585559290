import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSaleList() {
  // Use toast
  const toast = useToast()

  const refSaleListTable = ref(null)

  const perPage = ref(5)
  const totalSales = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const branchFilter = ref(null)
  const staffFilter = ref(null)
  const clientFilter = ref(null)
  const saleRegionFilter = ref(null)
  const statusFilter = ref(null)
  const itemFilter = ref(null)
  const dateFilterValue = ref(null)

  const current = new Date()
  const month = (current.getMonth() + 1) < 10 ? `0${(current.getMonth() + 1)}` : (current.getMonth() + 1)
  const date = (current.getDate()) < 10 ? `0${(current.getDate())}` : (current.getDate())
  const today = `${current.getFullYear()}-${month}-${date}`
  dateFilterValue.value = today

  const totalAmount = ref(0)
  const totalDiscount = ref(0)
  const netTotal = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refSaleListTable.value ? refSaleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSales.value,
    }
  })

  const refetchData = () => {
    refSaleListTable.value.refresh()
  }

  const resolveStatusVariant = status => {
    if (status === 0) return 'info'
    if (status === 1) return 'danger'
    if (status === 2) return 'warning'
    if (status === 3) return 'success'
    if (status === 4) return 'secondary'
    return 'primary'
  }
  watch([currentPage, perPage, branchFilter, staffFilter, clientFilter, saleRegionFilter, statusFilter, itemFilter, dateFilterValue], () => {
    refetchData()
  })

  const itemOptions = ref(JSON.parse(JSON.stringify([])))
  store
    .dispatch('common/fetchItems', { id_name: 1 })
    .then(response => {
      const { items } = response.data.data
      for (let i = 0; i < items.length; i += 1) {
        itemOptions.value.push({ label: items[i].name, value: items[i].id.toString() })
      }
    })
    .catch(error => {
      console.log(error)
    })

  const fetchSales = async (ctx, callback) => {
    store
      .dispatch('sale/fetchAll', {
        q: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
        branch_id: branchFilter.value,
        staff_id: staffFilter.value,
        client_id: clientFilter.value,
        sale_region_id: saleRegionFilter.value,
        item_id: itemFilter.value,
        status: statusFilter.value,
        date_range: dateFilterValue.value,
      })
      .then(response => {
        const { sales, total } = response.data.data

        callback(sales)
        totalSales.value = total
        totalAmount.value = response.data.data.total_amount
        totalDiscount.value = response.data.data.total_discount
        netTotal.value = response.data.data.net_total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error getting sale list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSales,
    perPage,
    currentPage,
    totalSales,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSaleListTable,

    refetchData,
    totalAmount,
    totalDiscount,
    netTotal,

    resolveStatusVariant,

    // Filters
    branchFilter,
    staffFilter,
    clientFilter,
    saleRegionFilter,
    statusFilter,
    itemFilter,
    dateFilterValue,

    itemOptions,
  }
}
